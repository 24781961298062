




import { BaseComponent, Component } from '@/components/BaseComponent';
import Spinner from '@/components/Spinner.vue';

@Component({
  components: {
    Spinner,
  },
})
export default class Callback extends BaseComponent {
  async created() {
    const params = new URLSearchParams(window.location.search);
    const result = await this.pds.callback(params);
    const clientId = this.session.getClientId();
    if (clientId) {
      this.authorization.setClientId(clientId);
    }
    this.router.changePage(result);
  }
}
